const config = {
  APP_STAGE: "qa",
  AUTH0_DOMAIN: "dividends360.us.auth0.com",
  AUTH0_CLIENT_ID: "5T5zULQdNOg0ZWW3Z3TA494VIMFc6vtk",
  LOGOUT_URL: "https://dividends360.webflow.io",
  RPC_API_URL: "https://sls-scus-qa-86fb25-apim.azure-api.net/rpc",
  YODLEE_API_URL: "https://development.api.yodlee.com/ysl",
  SENTRY_DSN: "https://526f2df07bcd4f748b4912b459f3f6bb@o556171.ingest.sentry.io/5941235",
  SOCKET_SERVER_URL: "https://d360-socket-server-qa.azurewebsites.net",
  TNC_URL: "https://figi360.com/terms-and-conditions",
  PLANNING_URL: "https://planning.qa.dividends360.com",
  FRESHDESK_API_KEY: "<FRESHDESK_API_KEY>",
};

export default config;
